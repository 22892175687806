import DatabaseService from "../services/database.js";
import Settings from "../services/settings.js";
import { activeWorkspaceStore } from "../services/stores.js";
import "./localized-stbl.js";
import Project, { ProjectFlags } from "./project.js";
/**
 * Model for an entire user workspace.
 */
export default class Workspace {
    //#region Initialization
    constructor(projects = []) {
        this.projects = projects;
    }
    /**
     * Parses a workspace from the given JSON and overwrites the settings
     * currently in storage.
     *
     * @param json JSON containing settings and workspace data
     */
    static async restoreFromJson(json) {
        return new Promise((resolve, reject) => {
            try {
                for (const setting in json.settings) {
                    Settings[setting] = json.settings[setting];
                }
                const projects = json.projects.map(({ uuid, metaData, stbl }) => {
                    DatabaseService.setItem("metadata", uuid, metaData);
                    DatabaseService.setItem("stbls", uuid, stbl);
                    return new Project(uuid, Project.deserializeMetaData(metaData), undefined);
                });
                const workspace = new Workspace(projects);
                resolve(workspace);
            }
            catch (err) {
                reject(err);
            }
        });
    }
    /**
     * Reads the existing workspace from storage.
     */
    static async fromStorage() {
        return new Promise(async (resolve, reject) => {
            try {
                const uuids = await DatabaseService.getAllKeys("metadata");
                const metaDatas = await DatabaseService.getAll("metadata");
                const projects = uuids.map((uuid, i) => {
                    return Project.deserialize(uuid, metaDatas[i]);
                });
                resolve(new Workspace(projects));
            }
            catch (err) {
                reject(err);
            }
        });
    }
    //#endregion Initialization
    //#region Public Methods
    /**
     * Adds a project to the workspace, saves it and its STBL to the DB, and
     * updates all subscribers to the workspace.
     *
     * @param project Project to add
     */
    addProject(project) {
        this.projects.push(project);
        project.saveToStorage();
        project.stbl.saveToStorage(project.uuid);
        this._updateSubscribers();
    }
    /**
     * Removes projects from the workspace, deletes them from storage, and
     * updates all subscribers to the workspace.
     *
     * @param projects Projects to delete
     */
    deleteProjects(projects) {
        const uuids = new Set(projects.map(p => p.uuid));
        this.projects = this.projects.filter(project => !uuids.has(project.uuid));
        uuids.forEach(uuid => {
            DatabaseService.removeItem("metadata", uuid);
            DatabaseService.removeItem("stbls", uuid);
        });
        this._updateSubscribers();
    }
    /**
     * Toggles the pins for the given projects. If at least one project is
     * unpinned, all will be pinned. Otherwise, all will be unpinned.
     *
     * @param projects Projects to toggle pins for
     */
    toggleProjectPins(projects) {
        const pinning = projects.some(p => !(p.hasFlags(ProjectFlags.Pinned)));
        projects.forEach(project => {
            project.setFlags(ProjectFlags.Pinned, pinning);
            project.saveToStorage(false);
        });
        this._updateSubscribers();
    }
    /**
     * Returns a JSON representation of this workspace.
     */
    async toJson() {
        return new Promise(async (resolve) => {
            const projects = [];
            for (let i = 0; i < this.projects.length; ++i) {
                const project = this.projects[i];
                try {
                    await project.loadStringTable();
                    projects.push({
                        uuid: project.uuid,
                        metaData: project.serializeMetaData(),
                        stbl: project.stbl.serialize()
                    });
                }
                catch (err) {
                    console.error(err);
                    const metaData = await DatabaseService.getItem("metadata", project.uuid);
                    const stbl = await DatabaseService.getItem("stbls", project.uuid);
                    projects.push({ uuid: project.uuid, metaData, stbl });
                }
            }
            const settings = {};
            for (const key in Settings)
                settings[key] = Settings[key];
            resolve({ version: Workspace.VERSION, settings, projects });
        });
    }
    //#endregion Public Methods
    //#region Private Methods
    _updateSubscribers() {
        activeWorkspaceStore.set(this);
    }
}
Workspace.VERSION = 0;
