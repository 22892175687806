//#region Types
export var SortOrder;
(function (SortOrder) {
    SortOrder[SortOrder["Chronological"] = 0] = "Chronological";
    SortOrder[SortOrder["Alphanumeric"] = 1] = "Alphanumeric";
    SortOrder[SortOrder["ReverseAlphanumeric"] = 2] = "ReverseAlphanumeric";
})(SortOrder || (SortOrder = {}));
export var FilterType;
(function (FilterType) {
    FilterType[FilterType["Contains"] = 0] = "Contains";
    FilterType[FilterType["ExactMatch"] = 1] = "ExactMatch";
    FilterType[FilterType["BeginsWith"] = 2] = "BeginsWith";
    FilterType[FilterType["EndsWith"] = 3] = "EndsWith";
    FilterType[FilterType["Regex"] = 4] = "Regex";
    FilterType[FilterType["KeyEquals"] = 5] = "KeyEquals";
})(FilterType || (FilterType = {}));
//#endregion Types
//#region Options
function getOptionsForEnum(enumObj) {
    return Object
        .values(enumObj)
        .filter(value => typeof value === "number")
        .map(value => {
        return {
            value,
            text: enumObj[value].replace(/([A-Z])/g, " $1").trim()
        };
    });
}
export const sortOrderOptions = getOptionsForEnum(SortOrder);
export const filterTypeOptions = getOptionsForEnum(FilterType);
//#endregion Options
//#region Functions
/**
 * Gets the entries to display using the provided sort order and filters.
 *
 * @param locale Locale to use for value comparisons
 * @param entries Original entries in chronological order
 * @param sortOrder Order to sort entries in
 * @param filters Filters to apply
 */
export function getEntriesToShow(locale, entries, sortOrder, filters) {
    return getSortedEntries(locale, filterEntries(locale, entries, filters), sortOrder);
}
function getSortedEntries(locale, entries, sortOrder) {
    switch (sortOrder) {
        case SortOrder.Alphanumeric:
            return [...entries].sort((a, b) => {
                const aValue = a.values.get(locale).toLowerCase();
                const bValue = b.values.get(locale).toLowerCase();
                if (aValue < bValue)
                    return -1;
                if (aValue > bValue)
                    return 1;
                return 0;
            });
        case SortOrder.ReverseAlphanumeric:
            return getSortedEntries(locale, entries, SortOrder.Alphanumeric).reverse();
        default:
            return entries;
    }
}
function filterEntries(locale, entries, filters) {
    let filteredEntries = entries;
    filters.forEach(filter => {
        filteredEntries = filteredEntries.filter(entry => {
            return testFilter(entry.key, entry.values.get(locale), filter);
        });
    });
    return filteredEntries;
}
function testFilter(key, value, filter) {
    try {
        if (filter.type === FilterType.KeyEquals) {
            const keyToMatch = parseInt(filter.text, 16);
            return key === keyToMatch;
        }
        else if (filter.type === FilterType.Regex) {
            const regex = new RegExp(filter.text);
            return regex.test(value);
        }
        else {
            const entryString = value.toLowerCase();
            const userString = filter.text.toLowerCase();
            switch (filter.type) {
                case FilterType.Contains:
                    return entryString.includes(userString);
                case FilterType.ExactMatch:
                    return entryString === userString;
                case FilterType.BeginsWith:
                    return entryString.startsWith(userString);
                case FilterType.EndsWith:
                    return entryString.endsWith(userString);
                default:
                    return true;
            }
        }
    }
    catch (e) {
        return false;
    }
}
//#endregion Functions
