let nextSubscriptionId = 0;
const subscriptions = new Map();
/**
 * Listens to keydown events.
 *
 * @param key Key to listen for
 * @param callback Function to call when key is pressed
 * @param options Options for key event
 * @returns Function that can be called to unsubscribe this listener
 */
export function subscribeToKey(key, callback, options) {
    const id = nextSubscriptionId++;
    const fn = (e) => {
        if (options?.ctrlOrMeta && !(e.ctrlKey || e.metaKey))
            return;
        if (options?.shift && !e.shiftKey)
            return;
        if (e.key === key) {
            if (options?.preventDefault)
                e.preventDefault();
            callback(e);
        }
    };
    subscriptions.set(id, fn);
    return () => subscriptions.delete(id);
}
function handleKeydown(e) {
    subscriptions.forEach(fn => {
        fn(e);
    });
}
document.addEventListener("keydown", handleKeydown);
