class _WindowManager {
    constructor() {
        this._nextId = 0;
        this._subscriptions = new Map();
    }
    subscribe(fn) {
        const id = this._nextId++;
        this._subscriptions.set(id, fn);
        return () => this._subscriptions.delete(id);
    }
    request(type, action = "open", args) {
        this._subscriptions.forEach(subscription => {
            subscription(type, action, args);
        });
    }
}
const WindowManager = new _WindowManager();
export default WindowManager;
