export var FloatingActionButtonColor;
(function (FloatingActionButtonColor) {
    FloatingActionButtonColor["Green"] = "var(--toolbar-green)";
    FloatingActionButtonColor["Cyan"] = "var(--toolbar-cyan)";
    FloatingActionButtonColor["Azure"] = "var(--toolbar-azure)";
    FloatingActionButtonColor["Red"] = "var(--toolbar-red)";
    FloatingActionButtonColor["Pink"] = "var(--toolbar-pink)";
    FloatingActionButtonColor["Purple"] = "var(--toolbar-purple)";
    FloatingActionButtonColor["Orange"] = "var(--toolbar-orange)";
})(FloatingActionButtonColor || (FloatingActionButtonColor = {}));
