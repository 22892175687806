import { writable } from "svelte/store";
import DocumentUtils from "src/lib/utilities/document.js";
import StorageService from "src/lib/services/storage.js";
import constants from "src/data/constants.json";
class StoredSetting {
    constructor(name, defaultValue, _onChangeCallbacks = []) {
        this.name = name;
        this.defaultValue = defaultValue;
        this._onChangeCallbacks = _onChangeCallbacks;
    }
    //#region Public Methods
    get(setting) {
        const stringValue = StorageService.getItem("s:" + setting);
        return stringValue ? this._parseValue(stringValue) : this.defaultValue;
    }
    set(setting, value) {
        StorageService.setItem("s:" + setting, this._stringifyValue(value));
        this._onChange(value);
    }
    addCallback(cb) {
        this._onChangeCallbacks.push(cb);
    }
    //#endregion Protected Methods
    //#region Private Methods
    _onChange(value) {
        this._onChangeCallbacks.forEach(cb => cb(value));
    }
}
//#endregion Abstract Types
//#region Classes
class StoredBoolean extends StoredSetting {
    constructor(name, defaultValue = false, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        return value === "true";
    }
    _stringifyValue(value) {
        return value ? "true" : "false";
    }
}
class StoredInteger extends StoredSetting {
    constructor(name, defaultValue = 0, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        const number = parseInt(value);
        return Number.isNaN(number) ? this.defaultValue : number;
    }
    _stringifyValue(value) {
        return value.toString();
    }
}
class StoredString extends StoredSetting {
    constructor(name, defaultValue, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        return value;
    }
    _stringifyValue(value) {
        return value;
    }
}
class StoredJson extends StoredSetting {
    constructor(name, defaultValue, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        return JSON.parse(value);
    }
    _stringifyValue(value) {
        return JSON.stringify(value);
    }
}
function getSettingsProxy(settingsBuilder) {
    const settings = {};
    for (const settingName in settingsBuilder) {
        const builder = settingsBuilder[settingName];
        settings[settingName] = new builder.cls(settingName, builder.defaultValue, builder.callbacks);
    }
    return new Proxy(settings, {
        get(target, prop) {
            return target[prop].get(prop);
        },
        set(target, prop, value) {
            target[prop].set(prop, value);
            SettingsSubscriptionManager.notifySubscribers(prop, value);
            return true;
        }
    });
}
const Settings = getSettingsProxy({
    defaultLocale: {
        cls: StoredInteger
    },
    entriesPerPage: {
        cls: StoredInteger,
        defaultValue: 9
    },
    hasWorkspace: {
        cls: StoredBoolean
    },
    disableBlur: {
        cls: StoredBoolean,
        callbacks: [
            (value) => DocumentUtils.toggleBooleanAttribute("data-allow-blur", !value)
        ]
    },
    disableToolbarColors: {
        cls: StoredBoolean,
        callbacks: [
            (value) => DocumentUtils.toggleBooleanAttribute("data-monochrome-toolbar", value)
        ]
    },
    isLightTheme: {
        cls: StoredBoolean,
        callbacks: [
            (value) => DocumentUtils.toggleLightTheme(value, !Settings.reduceMotion)
        ]
    },
    reduceMotion: {
        cls: StoredBoolean
    },
    showAllStrings: {
        cls: StoredBoolean
    },
    mainframeHacked: {
        cls: StoredBoolean,
        callbacks: [
            (value) => {
                DocumentUtils.toggleBooleanAttribute("data-hacker-theme", value);
                if (value) {
                    DocumentUtils.toggleLightTheme(false, false);
                }
                else {
                    DocumentUtils.toggleLightTheme(Settings.isLightTheme, false);
                }
            },
            (value) => {
                mainframeHackedStore.set(value);
            }
        ]
    },
    rickGif: {
        cls: StoredBoolean,
        callbacks: [
            (value) => {
                rickGifStore.set(value);
            },
            (value) => {
                if (value)
                    window.open(constants.links.rickRoll, "_blank");
            }
        ]
    },
    foundEasterEggs: {
        cls: StoredJson,
        defaultValue: []
    },
    disableEasterEggs: {
        cls: StoredBoolean
    },
    projectView: {
        cls: StoredInteger
    },
    downloadFileType: {
        cls: StoredString,
        defaultValue: "package"
    },
    downloadNamingConvention: {
        cls: StoredString,
        defaultValue: "project"
    },
    downloadLocales: {
        cls: StoredInteger,
        defaultValue: constants.specialValues.allLocales
    },
    sortOrder: {
        cls: StoredInteger,
        defaultValue: 0
    },
    translateHideKeys: {
        cls: StoredBoolean,
        defaultValue: false
    },
    useObjectJson: {
        cls: StoredBoolean,
        defaultValue: false,
    },
});
export default Settings;
class _SettingsSubscriptionManager {
    constructor() {
        this._nextId = 0;
        this._subscriptions = new Map();
    }
    subscribe(setting, fn) {
        const id = this._nextId++;
        this._subscriptions.set(id, { setting, fn });
        return () => this._subscriptions.delete(id);
    }
    notifySubscribers(setting, value) {
        this._subscriptions.forEach(subscription => {
            if (subscription.setting === setting)
                subscription.fn(value);
        });
    }
}
export const SettingsSubscriptionManager = new _SettingsSubscriptionManager();
//#endregion Subscriptions
//#region Stores
// Using stores instead of subscription because sometimes the settings aren't
// actually used, such as during string creation input
function easterEggFoundCallback(easterEgg) {
    return (value) => {
        if (value) {
            const foundList = Settings.foundEasterEggs;
            if (!foundList.includes(easterEgg)) {
                foundList.push(easterEgg);
                Settings.foundEasterEggs = foundList;
            }
        }
    };
}
export const mainframeHackedStore = writable(Settings.mainframeHacked);
mainframeHackedStore.subscribe(easterEggFoundCallback("hacker"));
export const rickGifStore = writable(Settings.rickGif);
rickGifStore.subscribe(easterEggFoundCallback("rickroll"));
export const prideFlagOverlayStore = writable(false);
prideFlagOverlayStore.subscribe(easterEggFoundCallback("pride"));
export const wompWompStore = writable(false);
wompWompStore.subscribe(easterEggFoundCallback("womp"));
//#endregion Stores
