export default class SelectionGroup {
    constructor(selectables, key, _refreshCallback) {
        this.selectables = selectables;
        this.key = key;
        this._refreshCallback = _refreshCallback;
        this._selectMode = false;
        this._selections = new Set();
    }
    get selectMode() {
        return this._selectMode;
    }
    get allSelectedKeys() {
        return Array.from(this._selections);
    }
    get allSelectedItems() {
        return this.selectables.filter(s => this._selections.has(s[this.key]));
    }
    get noneSelected() {
        return this._selectMode && this._selections.size === 0;
    }
    get numSelected() {
        return this._selections.size;
    }
    deselectAll() {
        this._selections.clear();
        this._refreshCallback();
    }
    isSelected(value) {
        return this._selections.has(value[this.key]);
    }
    selectAll() {
        this.selectables.forEach(s => this._selections.add(s[this.key]));
        this._refreshCallback();
    }
    toggleSelectMode(value) {
        this._selectMode = value ?? !this._selectMode;
        if (!this._selectMode) {
            this.deselectAll();
        }
        else {
            this._refreshCallback();
        }
    }
    toggleValue(value) {
        const key = value[this.key];
        const fn = this._selections.has(key) ? "delete" : "add";
        this._selections[fn](key);
        this._refreshCallback();
    }
}
